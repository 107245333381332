import React from "react"
import ServiceSidebarArchitecture from "./ServiceSidebarArchitecture"
import details1 from "../../assets/images/services/banner-migration.jpg"

const MigrationReplicationContent = ({ location }) => {
  return (
    <section className="services-details-area ptb-100">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-md-12">
            <div className="services-details-image">
              <img src={details1} alt="about" />
            </div>
          </div>
          <div className="col-lg-4 col-md-12">
            <ServiceSidebarArchitecture location={location} />
          </div>

          <div className="col-lg-12 col-md-12">
            <div className="services-details-desc">
              <span className="sub-title">SOFTWARE ARCHITECTURE DESIGN</span>
              <h3>Migration & Replication</h3>
              <h5>
                Design for initial migration of data at production start from
                legacy systems or manual entry. Design for migration at new
                version release upgrade of existing data structures. Replication
                of business data for reconciliation or data warehousing.
              </h5>
              <div className="content">
                <p>
                  <b>Data Migration</b> understood in a software development
                  context is a process of porting data from one component of the
                  system to another component of a system. Migration can be done
                  between two separate systems that need to move data, or
                  between two versions of the same system during a software
                  version upgrade. Typical use cases are:
                </p>
                <ul>
                  <li>
                    Moving data from a legacy system out to a newly created
                    system that will be used from now on (Go Live Migration)
                  </li>
                  <li>
                    Moving data from previous version of a database scheme to a
                    newer version where data structures have to be adjusted
                  </li>
                  <li>
                    Moving a subset of data for a tenant in a multitenant system
                    or SaaS. Typical when switching SaaS providers or seeding
                    account with existing data
                  </li>
                </ul>
              </div>
              <p>
                If the migration is a not one-time operation, then typically we
                would not name it migration but replication.
              </p>

              <div className="content">
                <p>
                  <b>Data Replication</b> is a type of backend integration that
                  deals specifically with transferring a large number of data
                  entries between landscape systems for operational purposes.
                  Microservices also use data replication to some extent. In
                  cases where we want to completely separate data models between
                  components, replication allows to transfer data in a near
                  real-time manner. Typically, we see two types of replication:
                </p>
                <ul>
                  <li>
                    Data replication in master/slave mode between whole systems
                    in a landscape or between business parties
                    <br /> (big complex migration with many objects)
                  </li>
                  <li>
                    Data replication between microservices to achieve local data
                    independency (small models 1-2 entities)
                  </li>
                </ul>
              </div>

              <div className="row align-items-center">
                <div className="col-lg-12 col-md-12">
                  <div className="content">
                    <h3>
                      Typical software challenges we encounter with customer
                      projects:
                    </h3>
                    <ul>
                      <li>
                        Lack of proper data model definition for migration (ex:
                        no object versioning)
                      </li>
                      <li>
                        Bad split of model objects for migration causing time
                        dependencies and synchronisation issues
                      </li>
                      <li>
                        Excessively large data sets for migration that cause
                        long system unavailability when migrating at Go Live or
                        upgrade
                      </li>
                      <li>
                        Migration that is not idempotent = can’t be executed
                        many times on same data set without breaking the data
                      </li>
                      <li>
                        Objects processed in wrong order cause data
                        inconsistency in target system or rejection of data at
                        all
                      </li>
                      <li>
                        No migration selectivity for fixing individual migration
                        errors
                      </li>
                      <li>
                        No data validation checks for data quality before
                        migration
                      </li>
                      <li>
                        No monitoring makes reporting on migration results and
                        errors impossible
                      </li>
                      <li>
                        Lack of performance because of bad bulk and bundle
                        processing including improper error handing of
                        transactions
                      </li>
                      <li>
                        Improper queue management causing message resending to
                        components
                      </li>
                      <li>
                        Bad usage of initial and delta mode in data stream
                      </li>
                      <li>No monitoring for data flows to alert staff early</li>
                      <li>
                        Lack of proper Migration documentation for debugging and
                        fixed in the future (No interface contract!)
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <p>
                Do you want to risk rolling back an entire Go Live of a system
                because of a failed migration?
              </p>
              <p>
                Do you want to suffer system errors and complete system block
                because of wrong data?
              </p>
              <p>
                {" "}
                Imagine users complaining that records are not updated in their
                system, or customers complaining about services not working
                because of old data. Or worse: Money discrepancies between
                systems in the financial services industry that raise fraud
                questions and contract disputes?
              </p>
              <h5>
                If you feel that you would benefit in your project by fixing
                such points or mitigating presented risks, we can help.
              </h5>

              <div className="content">
                <h3 className="pt-30">
                  What we can do to solve typical migration/replication
                  problems:
                </h3>
                <ul>
                  <li>
                    Fix migration interfaces addressing all known migration
                    issues based on our extensive experience
                  </li>
                  <li>
                    Analyse data domain and use proper migration processing
                    order that will not damage your data
                  </li>
                  <li>
                    Build a redundant solution that allows faster error fixing
                    and partial migrations
                  </li>
                  <li>
                    Introduce monitoring and reporting for stakeholders so that
                    they know if data is usable in target system
                  </li>
                  <li>
                    Optimise for better performance so that the migration window
                    time is minimal
                  </li>
                  <li>
                    Prepare documentation based on created design for later
                    reference
                  </li>
                  <li>
                    Help at development and final execution of migration at Go
                    Live
                  </li>
                  <li>
                    Provide support at and after Go-Live to achieve required
                    transfer quota
                  </li>
                </ul>
              </div>

              <h3 className="pt-30">Technology</h3>
              <p>
                Technologies & tools that we typically use in our migration and
                replication projects.
              </p>
              <ul className="technologies-features">
                <li>
                  <span>PLSQL/DBT</span>
                </li>
                <li>
                  <span>Airbyte/Meltano</span>
                </li>
                <li>
                  <span>Apache Nifi/Hadoop</span>
                </li>
                <li>
                  <span>ELT Tools</span>
                </li>
                <li>
                  <span>SpringBoot</span>
                </li>
                <li>
                  <span>Node.js</span>
                </li>
                <li>
                  <span>Kafka/RabittMQ</span>
                </li>
                <li>
                  <span>DataOps with Docker</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default MigrationReplicationContent
